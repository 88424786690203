import { quest } from "@fscrypto/domain";
import { cva } from "class-variance-authority";

export const linkButtonVariants = cva("", {
  variants: {
    variant: {
      none: "",
      avalanche: "bg-avalanche-gradient-2 text-white",
      flow: "bg-flow-secondary text-black",
      solana: "bg-solana-secondary text-white",
      aptos: "bg-aptos-secondary text-black",
      axelar: "bg-axelar-primary text-white",
      near: "bg-near-primary text-black",
      sei: "bg-sei-secondary text-white",
      thorchain: "bg-thorchain-primary text-black",
      blast: "bg-blast-primary text-black",
      kaia: "bg-kaia-primary text-black",
    },
  },
  defaultVariants: {
    variant: "none",
  },
});

export const backgroundVariants = cva("", {
  variants: {
    variant: {
      primary: "bg-gray-15 dark:bg-gray-70 text-gray-70 dark:text-gray-15",
      avalanche:
        "bg-gradient-to-br from-avalanche-gradient-1/90 from-[-27.67%] to-avalanche-gradient-2/90 to-[190.76%] text-white dark:from-avalanche-gradient-1 dark:to-avalanche-gradient-2",
      flow: "bg-flow-secondary text-black",
      solana: "bg-solana-secondary text-white",
      aptos: "bg-aptos-secondary text-black",
      axelar: "bg-axelar-primary text-white",
      near: "bg-near-primary text-black",
      sei: "bg-sei-secondary text-white",
      thorchain: "bg-gradient-to-bl from-thorchain-primary/90 to-thorchain-secondary/90 text-black",
      blast: "bg-blast-primary text-black",
      kaia: "bg-kaia-primary text-white",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export const textVariants = cva("", {
  variants: {
    variant: {
      primary: "text-contrast",
      avalanche:
        "dark:text-white/40 bg-gradient-to-r from-avalanche-gradient-1 to-avalanche-gradient-2 dark:from-avalanche-gradient-1/90 dark:to-avalanche-gradient-2/90 to-[190.76%] from-[-27.67%]",
      flow: "bg-flow-secondary",
      solana: "bg-gradient-to-l from-solana-gradient-1 from-[-8.86%] to-solana-gradient-2 to-[107.97%]",
      aptos: "bg-aptos-foreground",
      axelar: "bg-axelar-primary",
      near: "bg-near-primary",
      sei: "bg-sei-secondary",
      thorchain: "bg-gradient-to-bl from-thorchain-primary to-thorchain-secondary",
      blast: "bg-blast-secondary",
      kaia: "text-kaia-secondary",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export const tagVariants = cva("", {
  variants: {
    variant: {
      primary: "bg-gray-15 dark:bg-gray-70 text-gray-70 dark:text-gray-15",
      secondary: "bg-[rgba(48,105,254,0.15)] text-gray-70 dark:text-gray-15",
      avalanche:
        "bg-gradient-to-r from-avalanche-gradient-1 from-[-27.67%] to-avalanche-gradient-2 to-[190.76%] text-gray-15",
      flow: "bg-flow-secondary text-black",
      solana: "bg-gradient-to-bl from-solana-gradient-1 from-[-8.86%] to-solana-gradient-2 to-[107.97%]",
      sei: "bg-sei-secondary text-white dark:text-black",
      axelar: "bg-axelar-primary text-white dark:text-black",
      near: "bg-near-primary text-black",
      aptos:
        "bg-[url('https://res.cloudinary.com/dsr37ut2z/image/upload/assets/backgrounds/ecosystems/aptos-button-bg.png')] bg-cover text-gray-15",
      thorchain: "bg-gradient-to-bl from-thorchain-primary/90 to-thorchain-secondary/90 text-black",
      blast: "bg-blast-primary text-black",
      kaia: "bg-kaia-primary text-white",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export const stepProgressVariants = cva("", {
  variants: {
    variant: {
      primary: "bg-gray-20 dark:bg-gray-70",
      secondary: "bg-gray-50 dark:bg-gray-40",
      outlined: "bg-transparent ring-1 ring-gray-40 dark:ring-gray-50 text-gray-40 dark:text-gray-50",
      warning: "bg-warning text-shell",
      loading: "bg-transparent",
      avalanche:
        "bg-gradient-to-br from-avalanche-gradient-1/90 from-[-27.67%] to-avalanche-gradient-2/90 to-[190.76%] dark:from-avalanche-gradient-1 dark:to-avalanche-gradient-2",
      flow: "bg-flow-secondary",
      solana: "bg-blast-secondary",
      aptos: "bg-aptos-foreground",
      axelar: "bg-axelar-primary",
      near: "bg-near-primary",
      sei: "bg-sei-secondary",
      thorchain: "bg-gradient-to-bl from-thorchain-primary to-thorchain-secondary",
      blast: "bg-blast-secondary",
      kaia: "bg-kaia-secondary",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export const scoreChartBaseColor = {
  aptos: "#4FD1C5",
  avalanche: "#E84142",
  flow: "#02D87E",
  solana: "#A345FF",
  sei: "#8CABA9",
  axelar: "#0197FE",
  near: "#00EC97",
  thorchain: "#009FFC",
  blast: "#FFF845",
  kaia: "#EF5757",
};

export const bucketsColorRanges: Record<quest.QuestEcosystem | "primary", { fromColor: string; toColor: string }> = {
  primary: { fromColor: "hsla(230, 100%, 61%, 0.7)", toColor: "hsla(230, 100%, 61%, 1)" },
  aptos: {
    fromColor: "hsla(179, 100%, 41%, 0.5)",
    toColor: "hsla(179, 100%, 41%, 1)",
  },
  sei: {
    fromColor: "hsla(176, 16%, 61%, 0.7)",
    toColor: "hsla(176, 16%, 61%, 1)",
  },
  near: {
    fromColor: "hsla(158, 100%, 46%, 0.5)",
    toColor: "hsla(158, 100%, 46%, 1)",
  },
  solana: {
    fromColor: "hsla(270, 100%, 64%, 0.7)",
    toColor: "hsla(270, 100%, 64%, 1)",
  },
  avalanche: {
    fromColor: "hsla(359, 88%, 53%, 0.7)",
    toColor: "hsla(359, 88%, 53%, 1)",
  },
  flow: {
    fromColor: "hsla(155, 73%, 71%, 0.7)",
    toColor: "hsla(155, 73%, 71%, 1)",
  },
  axelar: {
    fromColor: "hsla(204, 99%, 50%, 0.7)",
    toColor: "hsla(204, 99%, 50%, 1)",
  },
  thorchain: {
    fromColor: "hsla(153, 90%, 56%, 0.7)",
    toColor: "hsla(153, 90%, 56%, 1)",
  },
  blast: {
    fromColor: "hsla(58, 100%, 85%, 0.7)",
    toColor: "hsla(58, 100%, 64%, 1)",
  },
  kaia: {
    fromColor: "hsla(360, 83%, 64%)",
    toColor: "hsla(360, 83%, 90%)",
  },
};
